import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createVuetify } from 'vuetify';
import 'vuetify/dist/vuetify.min.css'; // Ensure you are using css-loader
import { createI18n } from 'vue-i18n';
import Axios from 'axios';  // Import Axios to set up interceptors

const app = createApp(App);
const vuetify = createVuetify(); // Create a new instance of Vuetify

import gr from './locales/gr.json';

// Create the i18n instance with the locale messages
const i18n = createI18n({
  locale: 'gr', // set default locale
  fallbackLocale: 'gr', // set fallback locale
  messages: {
    gr
  }
});

// Axios Interceptor for Global Token Expiry Handling
Axios.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  const tokenExpiry = localStorage.getItem('token_expiry');

  if (tokenExpiry && new Date().getTime() > tokenExpiry) {
    // Token is expired, clear it and redirect to login
    localStorage.removeItem('token');
    localStorage.removeItem('token_expiry');
    router.push('/login');  // Redirect to login page
    return Promise.reject(new Error('Token expired'));
  }

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}, (error) => {
  return Promise.reject(error);
});

// Axios interceptor for handling 401 Unauthorized globally
Axios.interceptors.response.use(
  response => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Handle token expiration or unauthorized error
      localStorage.removeItem('token');
      localStorage.removeItem('token_expiry');
      router.push('/login');  // Redirect to login page
    }
    return Promise.reject(error);
  }
);

app.use(i18n);
app.use(router);
app.use(vuetify); // Use the Vuetify instance

app.mount('#app');
